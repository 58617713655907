<template>
   <div class="d-flex justify-center align-center pa-4">
      <v-card class="contacto-card" elevation="5">
         <v-img class="align-end contacto-img" position="center top" :src="require('../assets/imgs/team.jpg')"> </v-img>
         <v-card-title>Asesorías de Cuauhtémoc y Aplicaciones NG</v-card-title>
         <v-card-subtitle class="pb-0">Jose Mario Nieto Contreras</v-card-subtitle>
         <v-card-text>
            <div>
               Av. Américas 711 Local 5, Fracc. Campo Real, Cuauhtémoc, Chihuahua CP: 31512
               <br />
               <a href="tel:6255822378" class="text-decoration-none text--primary">6255822378</a> •
               <a href="tel:6255895747" class="text-decoration-none text--primary">6255895747</a>
               <br />
               <a href="mailto:admon@asesoriasdecuauhtemoc.com" class="text-decoration-none text--primary">admon@asesoriasdecuauhtemoc.com</a>
            </div>
         </v-card-text>
         <v-divider></v-divider>
         <v-card-actions>
            <v-btn class="ml-1" elevation="3" icon small href="https://wa.me/526255895747" target="_blank" rel="noopener noreferrer">
               <v-icon small>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn class="ml-1" elevation="3" icon small href="https://www.facebook.com/adc.cuauhtemoc.3" target="_blank" rel="noopener noreferrer">
               <v-icon small>mdi-facebook</v-icon>
            </v-btn>
         </v-card-actions>
      </v-card>
   </div>
</template>

<script>
export default {
   name: "Contacto",
};
</script>

<style scoped>
.contacto-card {
   max-height: 90vh;
   max-width: 700px;
   width: 100%;
}
.contacto-img {
   max-height: 300px;
}
</style>
